
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import { trackEvent, trackClickEvent } from '../../../../utils/analytics';
import Page from '../../../../components/CarouselPage';
import Pagination from '../../../../components/CarouselPagination';
import CarouselButton from '../../../../components/CarouselButton';
import Image from '../../../../components/Image';
import CarouselItem from './CarouselItem';
import { findHubName } from '../../../../utils/hubs';
import flourish from './imgs/flourish.svg';
import { romanize } from '../../../../utils';
import s from './CollectionCarousel.module.scss';
const propTypes = {
    eyebrow: PropTypes.string,
    content: PropTypes.array,
    theme: PropTypes.string,
    subhead1: PropTypes.string,
    subhead2: PropTypes.string
};
const componentName = 'CollectionCarousel';
const CollectionCarousel = ({ eyebrow = '', content = [], theme = 'Dark', subhead1 = '', subhead2 = '', }) => {
    const { componentRef, verticalIndex } = useVerticalIndex();
    const speed = 400;
    // Used to fix an issue where swiping triggered click event
    let dragging = false;
    const afterChange = /* istanbul ignore next */ () => {
        dragging = false;
    };
    const beforeChange = /* istanbul ignore next */ () => {
        dragging = true;
        setTimeout(() => {
            afterChange();
        }, speed);
    };
    const sliderSettings = {
        customPaging: (i) => <Page label={romanize(i + 1)} theme={theme.toLowerCase()}/>,
        appendDots: (dots) => (<>
        <div className={[s.upperPagination].join(' ')}>
          <Pagination pages={dots} containerClass={s.pages}/>
        </div>
      </>),
        speed,
        className: 'slider variable-width',
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        beforeChange,
        afterChange
    };
    const settings = { ...sliderSettings };
    const [activeIndex, setActiveIndex] = React.useState(0);
    let collectionSlider = useRef(null);
    const buttonTheme = theme === 'Light' ? 'dark' : 'light';
    const handleSlideChange = (index) => {
        let newIndex = index;
        if (index > content.length - 1) {
            newIndex = 0;
        }
        if (index < 0) {
            newIndex = content.length - 1;
        }
        trackEvent('Carousel Item Swiped', {
            carousel_name: 'Collection Carousel',
            previous_carousel_index: activeIndex + 1,
            carousel_index: newIndex + 1,
            location: 'Collection',
            carousel_item_text: content[newIndex] && content[newIndex].displayTitle ? content[newIndex].displayTitle : '',
            carousel_item_id: content[newIndex] && content[newIndex].contentfulId ? content[newIndex].contentfulId : ''
        });
        setActiveIndex(newIndex);
    };
    return (<div className={s.root} ref={componentRef}>
      <h2 className={[s.eyebrow, theme === 'Light' ? s.eyebrowDark : ''].join(' ')}>{eyebrow}</h2>
      {subhead1 && (<section>
          <div className={s.flourish}>
            <Image imageSet={[
                {
                    src: flourish
                },
            ]} alt="Flourish"/>
          </div>
          <div className={s.subhead1}>{subhead1}</div>
          {subhead2 && <div className={s.subhead2}>{subhead2}</div>}
        </section>)}
      <div className={s.sliderInner}>
        <div className={s.controls}>
          <CarouselButton type="prev" theme={buttonTheme} onClick={() => {
            handleSlideChange(activeIndex - 1);
            collectionSlider.slickPrev();
        }}/>
          <CarouselButton type="next" theme={buttonTheme} onClick={() => {
            handleSlideChange(activeIndex + 1);
            collectionSlider.slickNext();
        }}/>
        </div>
        <div className={s.slider}>
          <Slider {...settings} ref={(slider) => (collectionSlider = slider)}>
            {content.map((c, i) => {
            const articleLink = c.tags ? `/${findHubName(c.tags)}/${c.externalId}` : '';
            return (<CarouselItem image={c.teaserImage?.image?.file?.url ?? c.mainImage?.image?.file?.url ?? ''} title={c.displayTitle} description={c.intro} key={i} link={articleLink} theme={theme} onItemClick={(e) => {
                    /* istanbul ignore if */
                    if (dragging) {
                        e.preventDefault();
                    }
                    else {
                        trackClickEvent({
                            destination_url: articleLink,
                            label: c.displayTitle,
                            content_name: c.displayTitle,
                            content_type: c.contentTypeId,
                            content_id: c.contentfulId,
                            location: componentName,
                            vertical_index: verticalIndex,
                            horizontal_index: i
                        });
                    }
                }}/>);
        })}
          </Slider>
        </div>
      </div>
    </div>);
};
CollectionCarousel.propTypes = propTypes;
export default CollectionCarousel;

    async function __Next_Translate__getStaticProps__195ca0cad2f__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/CollectionCarousel/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195ca0cad2f__ as getStaticProps }
  